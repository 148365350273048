.homeOptions {
    width: 200px;
    height: 200px;
    background-color: white;
    text-align: center;
    border-radius: 10px;
    transition: box-shadow .3s;
    cursor: pointer;
    text-decoration: none;
}

.homeOptions:hover {
    width: 200px;
    height: 200px;
    background-color: white;
    text-align: center;
    border-radius: 10px;
    transition: box-shadow .3s;
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
   
}

.optionsArea {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.imageOption {
    height: 60px;
    width: 60px;
}

.optionName {
    font-weight: 400;
    margin-top: 25px;
    color: #504d4ddc;
    font-size: 15px;
}

.disabledHomeOptions {
    width: 200px;
    height: 200px;
    background-color: white;
    text-align: center;
    border-radius: 10px;
    transition: box-shadow .3s;
}

.optionRedirectGeneral {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
  }
  