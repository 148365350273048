#title {
    font-weight: bold;
}

#sentBy {
    font-size: 18px;
    margin-left: 30px;
}

#sentByEmail {
    font-style: normal;
    color: #036003;
}

#sugestion {
    font-size: 28px;
    font-weight: bold;
    margin-left: 30px;
}

#suggestionReceived {
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
}

.suggestionField {
    background-color: white;
    height: 220px;
    transition: all 400ms ease; 
    margin-top: 20px
}

.suggestionField:hover {
    background-color: white;
    height: 220px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    transition: all 400ms ease; 
}

#denyButton {
    width: 80px;
    border: none;
    background-color: #F33434;
    border-radius: 20px;
    margin-left: 30px;
    color: white;
}

#approveButton {
    width: 80px;
    border: none;
    background-color: #08a816e1;
    border-radius: 20px;
    margin-left: 10px;
    color: white;
;
}

#nextSuggestionsButton {
    margin-left: 20px;
}


#hideNextSuggestion {
    display: none;
}

#pagePreview {
    margin-top: 50px;
}

#hiddenPagePreview {
    display: none;
}

#maxPagesPreview {
    font-style: normal;
}

#hiddenMaxPagesPreview {
    display: none;
}

#currentPage {
    font-style: normal;
    font-weight: bold;
}

#amountOfSuggestions {
    font-style: normal;
    color: rgba(0, 0, 0, 0.144);
}

#hiddenAmountOfSuggestions {
    display: none;
}