#navbarArea {
    background-color: #46CC46;
}

#navLink {
    color: rgba(255, 251, 251, 0.432);
}

#iconsNavbar {
    color: rgba(255, 251, 251, 0.432);
}

#adminBrand {
    font-size: 25px;
    margin-right: 15px;
}

.titleNavbar {
    font-weight: 500;
}