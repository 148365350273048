.sectionTitle {
    font-weight: 700;
    font-size: 20px;
}

.sectionDiv {
    background-color: white;
    height: 850px;
    border-radius: 10px;
}

.sectionInput {
    text-align: left;
    box-sizing: border-box;
    width: 100%;
    outline: none;
    border: 1px solid #8181813f;
    padding-left: 10px;
    height: 50px;
    
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000000;
    opacity: 40%; /* Firefox */
  }

.currencyTable {
    text-align: center;
    

}

.borderless td, .borderless th {
    border: none;
}

.editCurrencyButton {
    border: 0;
    background-color: #8CFF8C;
    color: #014928;
    height: 40px;
    width: 120px;
    border-radius: 10px;
    transition: 0.3s;
}

.editCurrencyButton:hover {
    border: 0;
    background-color: #43b843;
    color: #ffffff;
    height: 40px;
    width: 120px;
    border-radius: 10px;
    transition: 0.3s;
}

.sectionHr {
    color: rgba(0, 0, 0, 0.205);
}

.table-hover:hover {
    background-color: rgb(241, 235, 235);
}